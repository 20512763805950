import { Action, createReducer, on } from '@ngrx/store';

import { updateUserFeatures } from './user.actions';
import { initialUserState, UserState } from './user.state';

/* istanbul ignore next, standard reducer pattern */
const reducer = createReducer<UserState>(
    initialUserState,

    on(updateUserFeatures, (state, action): UserState => {
        return {
            ...state,
            features: action.features,
        };
    }),
);

export function userReducer(state: UserState | undefined, action: Action) {
    return reducer(state, action);
}
