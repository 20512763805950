import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { PartnerWebSupportService } from 'app/shared/services/partner';
import { AppState } from 'app/state';

import { PartnerAction } from './';

@Injectable({
    providedIn: 'root',
})
export class PartnerEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<AppState>,
        private webSupportService: PartnerWebSupportService,
    ) {}

    public readPartnerDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerAction.readPartnerDetails),
            switchMap((_action) => {
                return this.webSupportService.tenant.getTenantDetails().pipe(
                    map((partnerDetails) => {
                        return PartnerAction.readPartnerDetailsSuccess({ partnerDetails: partnerDetails });
                    }),
                    catchError((error: Error) => {
                        return of(PartnerAction.readPartnerDetailsFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });
}
