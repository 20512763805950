export interface ProblemDetailsDto {
    detail: string | undefined;
    status: number;
    title: string;
    traceId: string | undefined;
    type: string;
}

export class WebSupportApiError extends Error {
    readonly problemDetails: ProblemDetailsDto;

    constructor(problemDetails: ProblemDetailsDto) {
        super(problemDetails.title);
        this.problemDetails = problemDetails;
    }
}
