import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';

import {
    EdgeGatewayDetailsDto,
    GatewayDetailsDto,
    GatewayOverviewQueryResultDto,
} from '../web-support/gateway.model';
import { WebSupportSubService } from '../web-support/web-support-sub.service';
import { ProjectType } from './enumerations';
import { ProjectTopology, ProjectTypeResult } from './interfaces';

@Injectable({
    providedIn: 'root',
})
export class PartnerGatewayService extends WebSupportSubService {
    constructor(http: HttpClient) {
        super(http);
    }

    public getAllGateways(): Observable<GatewayOverviewQueryResultDto> {
        const url = `${this.getApiUrl()}/partner/gateways`;

        return this.http
            .get<GatewayOverviewQueryResultDto>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }

    public getGatewayDetailsByAssetId(assetId: string): Observable<GatewayDetailsDto | undefined> {
        const url = `${this.getApiUrl()}/partner/gateways/site/${assetId}/details`;
        return this.http
            .get<GatewayDetailsDto>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }

    public getEdgeGatewayDetailsByGatewayId(gatewayId: string): Observable<EdgeGatewayDetailsDto> {
        const url = `${this.getApiUrl()}/partner/gateways/${gatewayId}/edge/details`;

        return this.http.get<EdgeGatewayDetailsDto>(url).pipe(catchError(this.handleError));
    }

    public pingGateway(gatewayId: string): Observable<number> {
        const url = `${this.getApiUrl()}/partner/gateways/${gatewayId}/edge/ping`;
        return this.http
            .post<number>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }

    public getProjectTopology(gatewayId: string): Observable<ProjectTopology> {
        const url = `${this.getApiUrl()}/partner/gateways/${gatewayId}/edge/project-topology`;
        return this.http
            .get<ProjectTopology>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }

    public getProjectType(gatewayId: string): Observable<ProjectType> {
        const url = `${this.getApiUrl()}/partner/gateways/${gatewayId}/edge/project-type`;
        return this.http.get<ProjectTypeResult>(url, this.getDefaultRequestOptions()).pipe(
            map((result) => result?.projectType ?? ProjectType.unknown),
            catchError(this.handleError),
        );
    }
}
