import { FeatureToggle } from '@priva/auth/current-user/lib/shared/current-user.interface';

import * as appState from 'app/state/app.state';

export interface AppState extends appState.AppState {
    UserState: UserState;
}

export interface UserState {
    features: FeatureToggle[];
}

export const initialUserState: UserState = {
    features: [],
};
