import { TenantDetailsDto } from 'app/shared/services/web-support/tenant.model';
import * as appState from 'app/state/app.state';

export interface AppState extends appState.AppState {
    PartnerState: PartnerState;
}

export interface PartnerState {
    selectedPartner: TenantDetailsDto | undefined;
    isLoading: boolean;
    hasLoadingFailed: boolean;
    loadError: Error | undefined;
}

export const initialPartnerState: PartnerState = {
    selectedPartner: undefined,
    isLoading: false,
    hasLoadingFailed: false,
    loadError: undefined,
};
