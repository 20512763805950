import { createAction, props } from '@ngrx/store';

import { TenantDetailsDto } from 'app/shared/services/web-support/tenant.model';

export const readPartnerDetails = createAction('[Partner] Read the partner details');
export const readPartnerDetailsSuccess = createAction(
    '[Partner] Read the partner details success',
    props<{ partnerDetails: TenantDetailsDto }>(),
);
export const readPartnerDetailsFailed = createAction(
    '[Partner] Read the partner details failed',
    props<{ loadError: Error }>(),
);
