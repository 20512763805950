import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeatureName } from 'app/state';

import { PartnerEffects, partnerReducer } from './state';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(FeatureName.Partner, partnerReducer),
        EffectsModule.forFeature([PartnerEffects]),
    ],
    exports: [],
})
export class PartnerFeatureStateModule {}
