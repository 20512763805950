import { HttpStatusCode } from '@angular/common/http';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PrivaStatus } from '@priva/styles/foundation';

import { ContentContainerState } from 'app/shared/components/content-container/content-container.model';
import { ControllerDto } from 'app/shared/services/partner';
import { ProjectType } from 'app/shared/services/partner/enumerations';
import { GatewayDiagnoseResult } from 'app/shared/services/partner/interfaces/gateway-diagnose-result.interface';
import {
    GatewayStateOffline,
    GatewayStateOnline,
    GatewayStateUninitialized,
    GatewayTypeEdge,
} from 'app/shared/services/web-support/gateway.constants';
import { FeatureName } from 'app/state';

import { EdgeGatewayModules } from '../enumerations';
import { CloudModuleStatus, ModuleStatus } from '../interfaces';
import { GatewayState } from './gateway.state';

const getGatewayFeatureState = createFeatureSelector<GatewayState>(FeatureName.Gateway);

export const getSelectedGatewayDetails = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.selectedGateway,
);
export const getLoadingState = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    return {
        isLoading: state.isLoading,
        loadingFailed: state.hasLoadingFailed,
    } as ContentContainerState;
});

export const getSelectedEdgeGatewayDetails = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.edgeGatewayDetails,
);
export const isLoadingDetails = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.isLoadingDetails,
);
export const edgeGatewayHasUpdates = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) =>
        state.edgeGatewayDetails?.updates !== undefined && state.edgeGatewayDetails.updates.length > 0,
);

export const getLoadError = createSelector(getGatewayFeatureState, (state: GatewayState) => state.loadError);
export const getSelectedGatewayId = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.selectedGateway?.gateway?.id,
);
export const isEdgeGateway = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.selectedGateway?.gateway?.type === GatewayTypeEdge,
);
export const isPingingEdgeGateway = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.isPingingEdgeAgent,
);
export const isPingEdgeAgentSuccess = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.pingEdgeAgentResult === HttpStatusCode.Ok,
);
export const edgeAgentConnectionState = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    if (state.isPingingEdgeAgent || state.pingEdgeAgentResult === 0) {
        return GatewayStateUninitialized;
    } else if (state.pingEdgeAgentResult === HttpStatusCode.Ok) {
        return GatewayStateOnline;
    } else {
        return GatewayStateOffline;
    }
});
export const isGatewayOnline = createSelector(
    getGatewayFeatureState,
    edgeAgentConnectionState,
    (_state: GatewayState, connectionState: string) => connectionState === GatewayStateOnline,
);
export const isGatewayOffline = createSelector(
    getGatewayFeatureState,
    edgeAgentConnectionState,
    (_state: GatewayState, connectionState: string) => connectionState === GatewayStateOffline,
);

export const isDiagnosing = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => state.isDiagnosing,
);
export const getdiagnoseResults = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    if (!state.diagnoseResults) {
        return [];
    }
    const list: GatewayDiagnoseResult[] = [];
    for (const moduleId in state.diagnoseResults) {
        list.push(state.diagnoseResults[moduleId]);
    }
    return list;
});

export const getNetworkSettings = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    if (!state.platformServiceReportedProperties?.network) {
        return undefined;
    }
    return state.platformServiceReportedProperties.network;
});
export const isSeparatedNetwork = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    if (!state.platformServiceReportedProperties?.network) {
        // This should be the default, so when not known, show it as separated network.
        return true;
    }
    const lan1 = state.platformServiceReportedProperties.network.LAN1;
    const lan2 = state.platformServiceReportedProperties.network.LAN2;
    const isSeparated =
        lan1.enabled && lan1.state === 'activated' && lan2.enabled && lan2.state === 'activated';
    return isSeparated;
});

export const getInfrastructureModuleViews = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    return state.infrastructureModulesViews;
});
export const getSelectedInfrastructureModuleView = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => {
        return state.selectedInfrastructureModuleView;
    },
);
export const getSelectedInfrastructureSystemModules = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => {
        if (!state.infrastructureModulesViews || !state.selectedInfrastructureModuleView) {
            return [];
        }
        const moduleResults = state.selectedInfrastructureModuleView.modules
            .filter((x) => isSystemModule(x))
            .map((module) => {
                return {
                    moduleId: module,
                    isSystemModule: true,
                    diagnoseResult: state.diagnoseResults[module],
                } as ModuleStatus;
            });
        return moduleResults;
    },
);
export const getSelectedInfrastructurePrivaModules = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => {
        if (!state.infrastructureModulesViews || !state.selectedInfrastructureModuleView) {
            return [];
        }
        const moduleResults = state.selectedInfrastructureModuleView.modules
            .filter((x) => !isSystemModule(x) && !isCloudModule(x))
            .filter((x) => filterOnProjectType(x, state.projectType))
            .map((module) => {
                return {
                    moduleId: module,
                    isSystemModule: true,
                    diagnoseResult: state.diagnoseResults[module],
                } as ModuleStatus;
            });
        return moduleResults;
    },
);
export const getSelectedInfrastructureCloudModules = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => {
        if (!state.infrastructureModulesViews || !state.selectedInfrastructureModuleView) {
            return [];
        }
        const moduleResults = state.selectedInfrastructureModuleView.modules
            .filter((x) => isCloudModule(x))
            .filter((x) => filterOnProjectType(x, state.projectType))
            .map((module) => {
                return {
                    moduleId: module,
                    isSystemModule: isSystemModule(module),
                    diagnoseResult: state.diagnoseResults[module],
                    logoImageUrl: getLogoImageUrl(module),
                    status: getCloudStatus(state.diagnoseResults[module]),
                } as CloudModuleStatus;
            });
        return moduleResults;
    },
);

export const isLoadingProjectTopology = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    return state.isLoadingProjectTopology;
});
export const hasLoadingProjectTopologyFailed = createSelector(
    getGatewayFeatureState,
    (state: GatewayState) => {
        return state.hasLoadingProjectTopologyFailed;
    },
);
export const getProjectTopology = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    return state.projectTopology;
});
export const getProjectTopologyControllers = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    if (!state.projectTopology?.project?.controllers) {
        return [];
    }
    return sortControllersOnIPAddress(state.projectTopology.project.controllers);
});
export const getProjectType = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    return state.projectType;
});
export const hasIPv4GatewayConflict = createSelector(getGatewayFeatureState, (state: GatewayState) => {
    const network = state.platformServiceReportedProperties?.network;
    if (!network) {
        return false;
    }

    // There is a gateway conflict when multiple lan ports have a default gateway configured.
    const gateways = [network.LAN1.ipv4.gateway, network.LAN2.ipv4.gateway, network.LAN3.ipv4.gateway];
    return gateways.filter((x) => x && x.length > 0).length > 1;
});

function isSystemModule(module: string): boolean {
    return module === EdgeGatewayModules.edgeAgent || module === EdgeGatewayModules.edgeHub;
}
function isCloudModule(module: string): boolean {
    // TODO: Add Remote Service; is it availble yet?
    return module === EdgeGatewayModules.edgeAgent || module === EdgeGatewayModules.platformService;
}
function filterOnProjectType(module: string, projectType: ProjectType | undefined): boolean {
    switch (module) {
        case EdgeGatewayModules.comprinetConnector:
            return projectType === ProjectType.hx;
        case EdgeGatewayModules.httpConnector:
            return projectType === ProjectType.blueId;
        default:
            return true;
    }
}

function getLogoImageUrl(moduleId: EdgeGatewayModules): string {
    switch (moduleId) {
        case EdgeGatewayModules.edgeAgent:
            return '/assets/images/system-overview/microsoft-azure.svg';
        default:
            return '/assets/images/system-overview/priva-logo.svg';
    }
}
function getCloudStatus(diagnoseResult: GatewayDiagnoseResult | undefined): PrivaStatus {
    if (!diagnoseResult || diagnoseResult.isDiagnosing) {
        return 'info';
    }

    if (diagnoseResult.canPing) {
        return diagnoseResult.pingResult ? 'success' : 'danger';
    } else {
        return diagnoseResult.isRunning ? 'success' : 'danger';
    }
}

function sortControllersOnIPAddress(controllers: ControllerDto[]): ControllerDto[] {
    return controllers.sort((controllerA, controllerB) => {
        const ipA = controllerA.ipAddress.split('.').map(Number);
        const ipB = controllerB.ipAddress.split('.').map(Number);

        for (let i = 0; i < 4; i++) {
            if (ipA[i] !== ipB[i]) {
                return ipA[i] - ipB[i];
            }
        }

        return 0; // They are equal
    });
}
