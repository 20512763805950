export const GatewayTypeCustom = 'Custom';
export const GatewayTypeEdge = 'Edge';
// export const GatewayTypeSX100 = 'SX100';

export const GatewayStateOnline = 'Online';
export const GatewayStateOffline = 'Offline';
export const GatewayStateUninitialized = 'Uninitialized';
export const GatewayStateUnknown = 'Unknown';

export const GatewayUpdateTypeFirmware = 'Firmware';
