import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';

import { GatewayAction } from 'app/modules/partner/shared/gateway-state';
import { AppState } from 'app/state';

import { WebSupportSubService } from '../web-support/web-support-sub.service';
import { GatewayDiagnoseResult } from './interfaces/gateway-diagnose-result.interface';

@Injectable({
    providedIn: 'root',
})
export class PartnerGatewayDiagnoseService extends WebSupportSubService {
    private eventSource: EventSource | undefined;

    constructor(
        private readonly store: Store<AppState>,
        private readonly zone: NgZone,
        readonly http: HttpClient,
    ) {
        super(http);
    }

    public startStream(gatewayId: string) {
        this.stopStream();

        const url = `${this.getApiStreamUrl()}/${gatewayId}/edge/diagnose-modules`;
        this.eventSource = new EventSource(url, { withCredentials: true });

        this.eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const gatewayDiagoseResult = data as GatewayDiagnoseResult;
            if (!gatewayDiagoseResult?.moduleId) {
                // eslint-disable-next-line no-console
                console.warn('Invalid gateway diagnose result', gatewayDiagoseResult);
                return;
            }

            this.zone.run(() => {
                this.store.dispatch(
                    GatewayAction.diagnoseGatewayUpdate({ diagnoseResult: gatewayDiagoseResult }),
                );
            });
        };

        this.eventSource.addEventListener('close', () => {
            // eslint-disable-next-line no-console
            console.warn('Server instructed to close the connection');
            this.stopStream();
            this.zone.run(() => {
                this.store.dispatch(GatewayAction.stopDiagnose());
            });
        });

        this.eventSource.onerror = (error) => {
            // eslint-disable-next-line no-console
            console.error('Error in stream', error);
            this.stopStream();
        };
    }

    public stopStream() {
        this.eventSource?.close();
        this.eventSource = undefined;
    }
}
