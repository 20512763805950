import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    UserFeaturePrivaWebSupportPartnerRead,
    UserFeaturePrivaWebSupportRead,
} from 'app/common/authorization/current-user.constants';
import { FeatureName } from 'app/state';

import { UserState } from './user.state';

const getUserFeatureState = createFeatureSelector<UserState>(FeatureName.User);

export const getUserFeatures = createSelector(getUserFeatureState, (state: UserState) => state.features);
export const isPartner = createSelector(getUserFeatureState, (state: UserState) => {
    if (!state.features) {
        return false;
    }
    return state.features.some((x) => x.name === UserFeaturePrivaWebSupportPartnerRead);
});
export const isInternal = createSelector(getUserFeatureState, (state: UserState) => {
    if (!state.features) {
        return false;
    }
    return state.features.some((x) => x.name === UserFeaturePrivaWebSupportRead);
});
