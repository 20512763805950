import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeatureName } from 'app/state';

import { UserEffects, userReducer } from './state';

@NgModule({
    declarations: [],
    imports: [StoreModule.forFeature(FeatureName.User, userReducer), EffectsModule.forFeature([UserEffects])],
    exports: [],
})
export class UserFeatureStateModule {}
