import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContentContainerState } from 'app/shared/components/content-container/content-container.model';
import { FeatureName } from 'app/state';

import { PartnerState } from './partner.state';

const getPartnerFeatureState = createFeatureSelector<PartnerState>(FeatureName.Partner);

export const getPartnerDetails = createSelector(
    getPartnerFeatureState,
    (state: PartnerState) => state.selectedPartner,
);
export const getLoadingState = createSelector(getPartnerFeatureState, (state: PartnerState) => {
    return {
        isLoading: state.isLoading,
        loadingFailed: state.hasLoadingFailed,
    } as ContentContainerState;
});

export const getLoadError = createSelector(getPartnerFeatureState, (state: PartnerState) => state.loadError);
