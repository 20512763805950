import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ProblemDetailsDto, WebSupportApiError } from './web-support.model';

export abstract class WebSupportSubService {
    protected constructor(protected http: HttpClient) {}

    protected getApiUrl(): string {
        return this.trimEndSlash(environment.configuration.uris.webSupportApiService);
    }

    protected getApiStreamUrl(): string {
        return this.trimEndSlash(environment.configuration.uris.streamService);
    }

    protected handleError(response: any) {
        if (response instanceof HttpErrorResponse) {
            if (typeof response.error === 'string') {
                return throwError(() => new Error(response.error));
            } else if (response.error === null) {
                return throwError(() => new Error());
            } else {
                return throwError(() => new WebSupportApiError(response.error as ProblemDetailsDto));
            }
        }

        return throwError(() => new Error('Unknown error.'));
    }

    protected getDefaultRequestOptions(): object {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
    }

    private trimEndSlash(text: string): string {
        if (text && text.endsWith('/')) {
            return text.substring(0, text.length - 1);
        }
        return text;
    }
}
