import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeatureName } from 'app/state';

import { GatewayEffects, gatewayReducer } from './state';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(FeatureName.Gateway, gatewayReducer),
        EffectsModule.forFeature([GatewayEffects]),
    ],
    exports: [],
})
export class GatewayFeatureStateModule {}
