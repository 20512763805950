import { Action, createReducer, on } from '@ngrx/store';

import { PartnerAction } from '.';
import { initialPartnerState, PartnerState } from './partner.state';

const reducer = createReducer<PartnerState>(
    initialPartnerState,

    on(PartnerAction.readPartnerDetails, (state) => {
        return {
            ...state,
            isLoading: true,
            hasLoadingFailed: false,
        };
    }),
    on(PartnerAction.readPartnerDetailsSuccess, (state, action) => {
        return {
            ...state,
            selectedPartner: action.partnerDetails,
            isLoading: false,
            hasLoadingFailed: false,
        };
    }),
    on(PartnerAction.readPartnerDetailsFailed, (state) => {
        return {
            ...state,
            selectedPartner: undefined,
            isLoading: false,
            hasLoadingFailed: true,
        };
    }),
);

export function partnerReducer(state: PartnerState | undefined, action: Action) {
    return reducer(state, action);
}
