import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CurrentUser, PrivaAuthCurrentUserService } from '@priva/auth/current-user';

import {
    UserFeaturePrivaWebSupportManageDevices,
    UserFeaturePrivaWebSupportPartnerRead,
    UserFeaturePrivaWebSupportRead,
} from './current-user.constants';

export enum UserPermission {
    ManageDevices,
    Read,
    PartnerRead,
}

@Injectable({
    providedIn: 'root',
})
export class AppCurrentUserService {
    constructor(private readonly privaAuthCurrentUserService: PrivaAuthCurrentUserService) {}

    public get currentUser(): Observable<CurrentUser> {
        return this.privaAuthCurrentUserService.user;
    }

    /**
     * Determines whether the user has the required permission.
     * @param userPermission The permission to check.
     * @returns True if the user has the required permission, false otherwise.
     */
    hasPermission(userPermission: UserPermission): Observable<boolean> {
        return this.privaAuthCurrentUserService.user.pipe(
            map((user) => {
                return this.checkPermission(user, userPermission);
            }),
        );
    }

    private checkPermission(user: CurrentUser, userPermission: UserPermission): boolean {
        switch (userPermission) {
            case UserPermission.ManageDevices:
                return (
                    user.features?.some(
                        (feature) => feature.name === UserFeaturePrivaWebSupportManageDevices,
                    ) ?? false
                );
            case UserPermission.Read:
                return (
                    user.features?.some((feature) => feature.name === UserFeaturePrivaWebSupportRead) ?? false
                );
            case UserPermission.PartnerRead:
                return (
                    user.features?.some(
                        (feature) => feature.name === UserFeaturePrivaWebSupportPartnerRead,
                    ) ?? false
                );
            default:
                return false;
        }
    }
}
