import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs';

import { AppCurrentUserService } from 'app/common/authorization/current-user.service';

import { updateUserFeatures } from './user.actions';

@Injectable({
    providedIn: 'root',
})
export class UserEffects {
    constructor(private currentUserService: AppCurrentUserService) {}

    public listenToUserChanges$ = createEffect(() =>
        this.currentUserService.currentUser.pipe(
            map((user) => {
                return updateUserFeatures({ features: user?.features ?? [] });
            }),
        ),
    );
}
