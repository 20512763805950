import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AssetDetailsDto } from '../web-support/asset.model';
import { WebSupportSubService } from '../web-support/web-support-sub.service';

@Injectable({
    providedIn: 'root',
})
export class PartnerAssetService extends WebSupportSubService {
    constructor(http: HttpClient) {
        super(http);
    }

    public getAssetDetailsByAssetId(assetId: string): Observable<AssetDetailsDto> {
        const url = `${this.getApiUrl()}/partner/assets/${assetId}/details`;

        const propertyFilterNames: string[] = ['GatewayId', 'ProjectId'];
        return this.http.get<AssetDetailsDto>(url, this.getDefaultRequestOptions()).pipe(
            map((details) => {
                if (details.asset.properties) {
                    details.asset.properties = details.asset.properties.filter((x) =>
                        propertyFilterNames.some((p) => x.key.toLowerCase() === p.toLowerCase()),
                    );
                }
                return details;
            }),
            catchError(this.handleError),
        );
    }
}
