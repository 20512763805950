import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TenantDetailsDto } from '../web-support/tenant.model';
import { WebSupportSubService } from '../web-support/web-support-sub.service';

@Injectable({
    providedIn: 'root',
})
export class PartnerTenantService extends WebSupportSubService {
    constructor(http: HttpClient) {
        super(http);
    }

    public getTenantDetails(): Observable<TenantDetailsDto> {
        const url = `${this.getApiUrl()}/partner/tenant/details`;

        return this.http
            .get<TenantDetailsDto>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }
}
